import {
    LeveledLogMethod,
    Logger,
    LoggerMethods,
    logMethods,
} from '../../../shared';

/**
 * Some browsers include extra properties on the error object that are not part
 * of the Error interface. This just allows us to access those properties.
 */
interface ErrorWithExtraBrowserProps extends Error {
    fileName?: string;
    sourceURL?: string;
    lineNumber?: number;
    line?: number;
    columnNumber?: number;
    column?: number;
    code?: string;
}

/**
 * Creates a client-side logger instance for use in Remix applications.
 * This logger sends log messages to the server via the `/remix-api/log` endpoint.
 *
 * The logger supports all standard logging methods (info, warn, error, debug) and
 * automatically handles error objects by extracting their message and stack trace.
 * All logs are sent asynchronously and failures are silently handled to prevent
 * disrupting the application.
 *
 * The best way to get an instance of the logger is to used the `useLogger` hook.
 *
 * @returns {Logger} A logger instance that implements the Logger interface
 *
 * @example
 * // Basic usage
 * logger.info('User logged in', { userId: '123' });
 *
 * // Logging errors
 * try {
 *   // some code that might throw
 * } catch (err) {
 *   logger.error(`Error while submitting email subscription.`, err);
 * }
 *
 * @see packages/web/app/routes/remix-api.log.ts
 * @see packages/web/app/entry.client.tsx
 */
export const createRemixClientLogger = (): Logger => {
    // The logger is instantiated outside the remix context
    // so it's just a simple fetch call
    const sendRequest = (
        method: keyof LoggerMethods,
        // This is just used as a reference to satisify the return type
        loggerRef: Logger
    ): LeveledLogMethod => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (message: string, ...meta: any[]): Logger => {
            try {
                const formData = new FormData();
                formData.append('method', method);
                formData.append('message', message);
                formData.append(
                    'meta',
                    JSON.stringify({
                        ...meta
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            .map((value: any) => {
                                if (value instanceof Error) {
                                    // Serialize the error object
                                    const errorWithExtraProps: ErrorWithExtraBrowserProps =
                                        value;
                                    const errorObj = {
                                        message: errorWithExtraProps?.message,
                                        stack: errorWithExtraProps?.stack,
                                        name: errorWithExtraProps?.name,
                                        cause: errorWithExtraProps?.cause,
                                        fileName:
                                            errorWithExtraProps?.fileName ||
                                            errorWithExtraProps?.sourceURL,
                                        lineNumber:
                                            errorWithExtraProps?.lineNumber ||
                                            errorWithExtraProps?.line,
                                        columnNumber:
                                            errorWithExtraProps?.columnNumber ||
                                            errorWithExtraProps?.column,
                                        code: errorWithExtraProps?.code,
                                    };
                                    return {
                                        error: errorObj,
                                    };
                                }
                                return value;
                            })
                            .reduce((prev, curr) => {
                                return { ...prev, ...curr };
                            }, {}),
                        component: 'remix-client',
                    })
                );

                sendLog(formData);
            } catch (err) {
                // Don't let client logging errors disrupt the application
                console.warn('Error sending log:', err);
            }
            return loggerRef;
        };
    };

    return logMethods.reduce((acc, method) => {
        acc[method] = sendRequest(method, acc);
        return acc;
    }, {} as LoggerMethods);
};

export const createMockLogger = (): Logger => {
    // The logger is instantiated outside the remix context
    // so it's just a simple fetch call
    const sendRequest = (
        method: keyof LoggerMethods,
        // This is just used as a reference to satisify the return type
        loggerRef: Logger
    ): LeveledLogMethod => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (message: string, ...meta: any[]): Logger => {
            console.log('Mock logger', method, message, meta);
            return loggerRef;
        };
    };

    return logMethods.reduce((acc, method) => {
        acc[method] = sendRequest(method, acc);
        return acc;
    }, {} as LoggerMethods);
};

const sendLog = async (body: FormData) => {
    try {
        const response = await fetch('/remix-api/log', {
            method: 'POST',
            body: body,
        });

        if (!response.ok) {
            console.warn(`Log endpoint returned status ${response.status}`);
        }
    } catch (_) {
        // Silent failure - client logging should never break the application
        return;
    }
};
